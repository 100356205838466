<template>
  <div class="w-100">
    <div
      v-if="!image"
      @drop.stop.prevent="handleDrop"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      class="file-input text-center"
      :class="isDragOver && 'isDragOver'"
      :style="`width: ${width}px`"
    >
      <section v-if="isDragOver || uploadDisabled" class="upload-placeholder">
        <b-icon icon="upload" />
        <p class="m-0">{{ label || "Arraste e solte arquivos aqui" }}</p>
        <span class="d-flex mx-auto text-center justify-content-center">
          ou
          <label
            for="file-upload"
            class="custom-file-upload text-primary a pointer ml-1"
          >
            procure o arquivo
          </label>
        </span>
        <input
          @change="handleInput"
          id="file-upload"
          type="file"
          style="position: absolute; opacity: 0; height: 0"
        />
      </section>

      <div>
        <b-card
          v-for="(file, index) in files"
          :key="index"
          class="d-flex p-2 my-2 list-files"
          :border-variant="hasError ? 'danger' : ''"
          no-body
        >
          {{ file.url }}
          <span>{{ file.name }} ({{ file.size }} kb)</span>
          <a @click="handleRemoveFile(file)" size="sm" class="mb-0 icon-action">
            <b-icon icon="trash" class="pointer" />
          </a>
        </b-card>
      </div>
      <span class="text-danger w-100 text-left d-block" v-if="hasError">
        {{ messageError }}
      </span>
    </div>
    <div v-else>
      <div>
        <div class="d-flex justify-content-end mb-1" v-if="!disableRotation">
          <b-icon
            @click="handleRotate(-90)"
            icon="arrow-counterclockwise"
            class="pointer"
          />
          <b-icon
            @click="handleRotate(90)"
            icon="arrow-clockwise"
            class="pointer"
          />
        </div>
        <vue-croppie
          :style="`width: ${container}`"
          ref="croppieRef"
          :mouseWheelZoom="true"
          :enableResize="true"
          :enableZoom="enableZoom"
          :boundary="{ width: width || '100%', height: height || 300 }"
          :viewport="{ width: width || 200, height: height || 200 }"
          @result="result"
          @update="autoUpload ? handleUpdateCroppie() : null"
        >
        </vue-croppie>

        <section>
          <label
            for="file-upload"
            class="
              btn btn-primary
              ml-1
              d-flex
              align-items-center
              justify-content-center
            "
          >
            {{ label || "Alterar foto" }}
          </label>

          <input
            @change="handleInput"
            id="file-upload"
            type="file"
            style="position: absolute; opacity: 0; height: 0"
          />
        </section>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        block
        v-if="!uploadDisabled && !autoUpload"
        @click="handleUpload"
        :disabled="onUpload"
        class="pull-right"
        variant="primary"
      >
        <b-spinner v-if="onUpload" small class="mr-2" />
        {{ actiontLabel || "Enviar" }}
      </b-button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import _ from "lodash";

export default {
  props: {
    label: {
      type: String,
    },
    actiontLabel: {
      type: String,
    },
    container: {
      type: String,
    },
    autoUpload: {
      type: Boolean,
    },
    disableRotation: {
      type: Boolean,
    },
    enableZoom: {
      type: Boolean,
    },
    image: {
      type: Boolean,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      cropped: null,
      isDragOver: false,
      onUpload: false,
      hasError: false,
      messageError: "",
      files: [],
      url: require("@/assets/images/logo.png"),
    };
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  methods: {
    handleUpdateCroppie: _.debounce(function() {
      this.handleCrop();
    }, 500),

    handleCrop() {
      let options = {
        format: "jpeg",
        circle: false,
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.$emit("upload", output);
        this.cropped = output;
      });
    },

    result(output) {
      this.cropped = output;
    },

    handleRotate(rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    handleInput(e) {
      this.files = [...e.target.files];

      const file = this.files[0];

      if (this.image) {
        this.url = URL.createObjectURL(file);
        this.$refs?.croppieRef.bind({
          url: this.url,
        });
      }

      if (this.autoUpload) {
        this.handleUpload();
      }
    },
    handleDragOver() {
      this.isDragOver = true;
    },
    handleDragLeave() {
      this.isDragOver = false;
    },
    handleDrop(e) {
      this.isDragOver = false;
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) {
        return;
      }
      if (this.image) {
        return;
      }
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
      if (this.autoUpload) {
        this.handleUpload();
      }
    },
    handleRemoveFile(file) {
      this.files = this.files.filter((f) => {
        return f !== file;
      });
      this.messageError = "";
      this.hasError = false;
    },
    uploadFetch(formData) {
      return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_UPLOAD_URL;
        axios({
          method: "post",
          url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
          .then(({ data, status }) => {
            if (status === 201) {
              this.hasError = false;
              this.$emit("upload", data);
            } else {
              this.hasError = true;
            }
            resolve({ data, status });
          })
          .catch((error) => {
            this.hasError = true;
            const alloweds = error.response?.data?.errors?.[0].message.split(
              ":"
            );
            this.messageError = `São permitidas as seguintes extensões: ${alloweds[1]}`;
            reject(error);
          })
          .finally(() => (this.onUpload = false));
      });
    },
    handleUpload() {
      this.onUpload = true;
      if (this.image) {
        this.handleCrop();
        return;
      }

      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("file", file);
      }

      this.uploadFetch(formData);
    },
  },
};
</script>

<style lang="scss">
.file-input {
  width: 100%;
  padding: 20px 0;
  text-align: center;

  .upload-placeholder {
    svg {
      display: block;
      margin: 24px auto 12px;
      font-size: 40px;
    }
  }

  &.isDragOver {
    background: #cde9ff;
  }

  .list-files {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .custom-file-upload {
    font-size: 14px;
  }
}
</style>
